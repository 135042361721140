import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table } from 'semantic-ui-react';
import ColorPickerComponent from './ColorPickerComponent';
import { saveChartUrl, getChartUrl, checkChartUrlValidity } from './utils';

const TabCharFreq = () => {
  const [charFreq, setCharFreq] = useState([]);
  const [chartUrl, setChartUrl] = useState('');

  const [barFillColor, setBarFillColor] = useState(() => {
    const savedBarFillColor = JSON.parse(localStorage.getItem('charFreqBarFillColor'));
    return savedBarFillColor || { r: 241, g: 112, b: 19, a: 1 };
  });
  const [barEdgeColor, setBarEdgeColor] = useState(() => {
    const savedBarEdgeColor = JSON.parse(localStorage.getItem('charFreqBarEdgeColor'));
    return savedBarEdgeColor || { r: 35, g: 35, b: 35, a: 1 };
  });

  const handleSetBarFillColor = (newColor) => {
    setBarFillColor(newColor);
    localStorage.setItem('charFreqBarFillColor', JSON.stringify(newColor));
  };
  const handleSetBarEdgeColor = (newColor) => {
    setBarEdgeColor(newColor);
    localStorage.setItem('charFreqBarEdgeColor', JSON.stringify(newColor));
  };

  useEffect(() => {
    const storedChartUrl = getChartUrl('charFreqChartUrl');
    if (storedChartUrl) {
      setChartUrl(storedChartUrl);
    }

    const storedResults = localStorage.getItem('analysis_results');
    if (storedResults) {
      const analysisData = JSON.parse(storedResults);
      const charFreqData = analysisData.char_freq_distribution;
      if (charFreqData) {
        const sortedCharFreqArray = Object.entries(charFreqData).sort((a, b) => b[1] - a[1]);
        setCharFreq(sortedCharFreqArray);
      }
    }
  }, []);

  const handleRedrawChart = async () => {
    const apiBaseUrl = 'https://nakfhtdj69.execute-api.us-east-2.amazonaws.com/production';
    //const apiBaseUrl = window.location.hostname === 'localhost'
      //? 'http://localhost:5000'
      //: 'https://domain-pivot-flask.fly.dev';

    const postData = {
      chart_type: 'bar',
      chart_title: 'Character Frequencies',
      chart_xlabel: 'Character',
      chart_ylabel: 'Occurrences',
      data: charFreq,
      barFillColor: `rgba(${barFillColor.r}, ${barFillColor.g}, ${barFillColor.b}, ${barFillColor.a})`,
      barEdgeColor: `rgba(${barEdgeColor.r}, ${barEdgeColor.g}, ${barEdgeColor.b}, ${barEdgeColor.a})`
    };

    try {
      const response = await axios.post(`${apiBaseUrl}/createchart`, postData);
      if (response.data && response.data.chartUrl) {
        const fullChartUrl = `${apiBaseUrl}${response.data.chartUrl}`;
        saveChartUrl('charFreqChartUrl', fullChartUrl);
        setChartUrl(fullChartUrl);
      } else {
        console.error('No chart URL in response');
      }
    } catch (error) {
      console.error('Error creating chart:', error);
    }
  };

  useEffect(() => {
    (async () => {
      if (chartUrl && !(await checkChartUrlValidity(chartUrl))) {
        handleRedrawChart();
      }
    })();
  }, [chartUrl]);

  return (
    <div className="flex-container">
      <div className="chart-container">
        <div className="controls-container">
          <ColorPickerComponent
            color={barFillColor}
            setColor={handleSetBarFillColor}
            storageKey="charFreqBarFillColor"
            heading="Bar Fill Color"
          />
          <ColorPickerComponent
            color={barEdgeColor}
            setColor={handleSetBarEdgeColor}
            storageKey="charFreqBarEdgeColor"
            heading="Bar Edge Color"
          />
          <button onClick={handleRedrawChart}>Redraw Chart</button>
        </div>
        {chartUrl && (
          <img src={chartUrl} alt="Character Frequency Chart" className="responsive-chart" />
        )}
      </div>
      <div className="table-container">
        <Table celled inverted stackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Character</Table.HeaderCell>
              <Table.HeaderCell>Occurrences</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {charFreq.map(([char, count], index) => (
              <Table.Row key={index}>
                <Table.Cell textAlign="center">{char}</Table.Cell>
                <Table.Cell textAlign="right">{count}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        {charFreq.length === 0 && <p>No character frequency data available.</p>}
      </div>
    </div>
  );
};

export default TabCharFreq;
