import React, { useState } from 'react';
import { SketchPicker } from 'react-color';

const ColorPickerComponent = ({ color, setColor, storageKey, heading }) => {
  const [isPickerVisible, setIsPickerVisible] = useState(false);

  const handleColorChange = (color) => {
    setColor(color.rgb);
    localStorage.setItem(storageKey, JSON.stringify(color.rgb));
  };

  const togglePicker = () => {
    setIsPickerVisible(!isPickerVisible);
  };

  // Style for the color button
  const buttonStyle = {
    backgroundColor: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
    border: `2px solid rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
    color: '#fff',
    padding: '10px 15px',
    cursor: 'pointer'
  };

  return (
    <div>
      <button style={buttonStyle} onClick={togglePicker}>{heading}</button>
      {isPickerVisible && <SketchPicker color={color} onChangeComplete={handleColorChange} />}
    </div>
  );
};

export default ColorPickerComponent;

