import React from "react";
import { PageFooterHyperlink } from "./page-footer-hyperlink";

export const PageFooter = () => {
  const resourceList = [
    {
      path: "https://domain-pivot.org/",
      label: "Dissect Cyber",
    },
    {
      path: "https://zetalytics.com/",
      label: "a Rhode Island Public Benefit Corporation",
    },
  ];

  return (
    <footer className="page-footer">
      <div className="page-footer-grid">
        <div className="page-footer-grid__info">
          <div className="page-footer-info__message">
            <p className="page-footer-message__headline">
            </p>
          </div>
          <div className="page-footer-info__resource-list">
            {resourceList.map((resource) => (
              <div
                key={resource.path}
                className="page-footer-info__resource-list-item"
              >
                <PageFooterHyperlink path={resource.path}>
                  {resource.label}
                </PageFooterHyperlink>
              </div>
            ))}
          </div>
        </div>
        <div className="page-footer-grid__brand">
          <div className="page-footer-brand">
		<img className="page-footer-brand__logo" src="https://sink.glass/logo.png" width="50" style={{opacity: 0.9}} />
            <PageFooterHyperlink path="https://domain-pivot.org/">
             Copyright 2024 Dissect Cyber Inc
            </PageFooterHyperlink>
          </div>
        </div>
      </div>
    </footer>
  );
};
