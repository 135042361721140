import React from "react";
import { PageLayout } from "../components/page-layout";

export const HomePage = () => (
  <PageLayout>
    <p>Click login and use a Microsoft, Google, LinkedIn, or Github account to authenticate.</p>
	<p style={{ fontSize: '0.8rem' }}>If you don't use the login that you originally gave us, you may be denied access.</p>
	<p style={{ fontSize: '0.8rem' }}> Just tell us which login you are using and we will add you.</p>
  </PageLayout>
);

