import React from "react";

export const SignupButton = () => {

  const handleRequestAccess = () => {
    window.open('https://domain-pivot.org/?openChat=true', '_blank');
  };

  return (
    <button className="button__request-access" onClick={handleRequestAccess}>
      Request Access
    </button>
  );
};

