import React, { useState, useEffect } from 'react';
import 'semantic-ui-css/semantic.min.css';
import { Tab, Accordion as SemanticAccordion, Progress, Button, Icon, Label } from 'semantic-ui-react';
import './App.css';
import {
  FaCloud, FaServer, FaCodeBranch, FaUserSecret, FaDatabase, FaBroadcastTower, FaDesktop, FaMobileAlt, FaLaptop, FaQuestion,
  FaNetworkWired, FaUserTie, FaTerminal, FaBuilding
} from 'react-icons/fa';
import './semantic.css';
import TabUpload from './TabUpload';
import TabTldFreq from './TabTldFreq';
import TabCharFreq from './TabCharFreq';
import TabNumFreq from './TabNumFreq';
import TabLenFreq from './TabLenFreq';

// Define a mapping from security levels to colors
const securityLevelColors = {
  'max security': 'green',
  'moderate': 'orange',
  'vulnerable': 'red',
  'missing assessment': 'orange', // As unknown are potentially vulnerable, they are in orange
};


const MainApp = () => {
  const [data, setData] = useState([]);

  useEffect(() => {

  const savedDomains = localStorage.getItem('domains');
  if (savedDomains) {
    setData(JSON.parse(savedDomains));
  }

  }, []);

// State for details data
const [detailData, setDetailData] = useState([]);

// State to track the expanded item type
const [expandedItemType, setExpandedItemType] = useState(null);

// Function to toggle accordion for any item type
const toggleAccordion = (itemType) => {
  setExpandedItemType(expanded => expanded === itemType ? null : itemType);
};

// TABS

const panes = [
  { menuItem: 'Upload Domains List', render: () => <Tab.Pane><TabUpload setData={setData} /></Tab.Pane> },
  { menuItem: 'TLD Freq', render: () => <Tab.Pane><TabTldFreq /></Tab.Pane> },
  { menuItem: 'Character Freq', render: () => <Tab.Pane><TabCharFreq /></Tab.Pane> },
  { menuItem: 'Numeric Freq', render: () => <Tab.Pane><TabNumFreq /></Tab.Pane> },
  { menuItem: 'Hostname Length', render: () => <Tab.Pane><TabLenFreq /></Tab.Pane> },
  { menuItem: 'Docs', render: () => <Tab.Pane>slides and video</Tab.Pane> },
  // ... Add more panes as needed
];

  const handleTabChange = (e, { activeIndex }) => {
    console.log('Selected tab index:', activeIndex);
    // Perform actions on tab change, like setting state or fetching data
  };

// JSX
return (
<>
    <div class="ui inverted attached tabular menu">	
      <Tab onTabChange={handleTabChange} panes={panes} />
    </div>

  </>
);

};

export default MainApp;

