import axios from 'axios';

// Function to save chart URL to local storage
export const saveChartUrl = (key, url) => {
  localStorage.setItem(key, url);
};

// Function to retrieve chart URL from local storage
export const getChartUrl = (key) => {
  return localStorage.getItem(key);
};

// Function to check chart URL validity
export const checkChartUrlValidity = async (url) => {
  try {
    const response = await axios.get(url);
    return response.status === 200;
  } catch (error) {
    return false;
  }
};

