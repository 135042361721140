import React, { useState, useEffect } from 'react';
import { Button, Dropdown } from 'semantic-ui-react';
import axios from 'axios';

const TabUpload = ({ setData }) => {
  const [dropdownValue, setDropdownValue] = useState(localStorage.getItem('selectedCSV') || ''); // Initialize from localStorage
  const [isUnsaved, setIsUnsaved] = useState(false); // Reintroduced isUnsaved
  const [textAreaValue, setTextAreaValue] = useState('');
  const [selectedCSV, setSelectedCSV] = useState('');
  const [file, setFile] = useState(null);
  const [saveConfirmation, setSaveConfirmation] = useState('');

  const csvFiles = [
    'bobpublic88.gmail.com.csv',
    'dns.cloudflare.com.csv',
    'mayamai1241.gmail.com.csv',
    'tbpublic88.gmail.com.csv',
    'zhanghang8819.gmail.com.csv',
  ];

  const fileOptions = csvFiles.map(file => ({ key: file, text: file, value: file }));

  useEffect(() => {
    const savedDomains = localStorage.getItem('domains');
    if (savedDomains) {
      setTextAreaValue(JSON.parse(savedDomains).join('\n'));
    }
  }, []);

  // Determine the API base URL based on where the React app is running
  // const apiBaseUrl = window.location.hostname === 'localhost' ? 'http://localhost:5000' : 'https://nakfhtdj69.execute-api.us-east-2.amazonaws.com/production';
  const apiBaseUrl = 'https://nakfhtdj69.execute-api.us-east-2.amazonaws.com/production';

  const handleTextAreaChange = (e) => {
    setTextAreaValue(e.target.value);
    setIsUnsaved(true);
    setSaveConfirmation(''); // Clear the save confirmation message
  };

  const handleDropdownChange = (e, { value }) => {
    localStorage.setItem('selectedCSV', value); // Save to localStorage
    setDropdownValue(value); // Update the Dropdown's value
    fetch(`${process.env.PUBLIC_URL}/${value}`)
      .then(response => response.text())
      .then(data => {
        setTextAreaValue(data);
        // Assuming the CSV data is one domain per line
        const domains = data.split('\n').filter(line => line.trim() !== '');
        setSelectedCSV(domains.join('\n'));
      })
      .catch(err => console.error('Error loading file:', err));
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const analyzeData = (data) => {
    const domains = data.split('\n').filter(line => line.trim() !== '');
    localStorage.setItem('domains', JSON.stringify(domains));
    setData(domains);
    setSaveConfirmation('Domains analyzed and saved successfully!');
    // Call the API to analyze the domains
    analyzeDomains(domains);
  };

  const analyzeDomains = (domains) => {
    axios.post(`${apiBaseUrl}/analyze`, { domainList: domains })
      .then(response => {
        localStorage.setItem('analysis_results', JSON.stringify(response.data));

        // Reset chart URLs for all tabs
        const chartTabs = ['numFreqChartUrl', 'lenFreqChartUrl']; // Add all relevant chart keys here
        chartTabs.forEach(tab => localStorage.setItem(tab, 'redraw.png'));

        setIsUnsaved(false);
        setSaveConfirmation('Domains analyzed and saved successfully!');
      })
      .catch(error => {
        console.error('Error during domain analysis:', error);
      });
  };

  return (
    <div className="tab-upload-container">
      <div className="input-column">
        <p>Paste in a List</p>
        <textarea
          value={textAreaValue}
          onChange={handleTextAreaChange}
          placeholder="Paste domain names here, one per line"
          rows="10"
        />
        <Button onClick={() => analyzeData(textAreaValue)} disabled={!isUnsaved}>
          Analyze and Save
        </Button>
      </div>

      <div className="input-column">
        <p>Try Sample Data</p>
        <Dropdown
          placeholder='Select CSV File'
          fluid
          selection
          value={dropdownValue} // Controlled value
          options={fileOptions}
          onChange={handleDropdownChange}
        />
        <Button onClick={() => analyzeData(selectedCSV)}>
          Analyze Sample Data
        </Button>
      </div>

      <div className="input-column">
        <p>Upload a File</p>
        <input type="file" onChange={handleFileChange} />
        <Button onClick={() => {
          // Read and analyze file content
          const reader = new FileReader();
          reader.onload = (e) => analyzeData(e.target.result);
          if (file) reader.readAsText(file);
        }}>
          Analyze File
        </Button>
      </div>

      {saveConfirmation && <div style={{ marginTop: '10px', color: 'green' }}>{saveConfirmation}</div>}
    </div>
  );
};

export default TabUpload;

